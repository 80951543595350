import React from 'react';

import Slides from './_slides';
import About from './_about';
import Services from './_services';
import Contact from './_contact';

const Content = () => {
    return (
        <div>
            <Slides />
            <About />
            <Services />
            <section id="cta-1">
                <div className="container">
                    <h1>VOUS ÊTES UN ENTREPRENEUR ET DÉSIREZ VOUS IMPLANTER EN AFRIQUE?</h1>
                    <a href="#contact" className="btn border-white btn-lg">CONTACTEZ-NOUS DÈS AUJOURD'HUI</a>
                </div>
            </section>
            <Contact />
        </div>
    );
}

export default Content;