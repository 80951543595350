import React from 'react';

const TopBar = () => {
    return (
        <div className="top-bar-dark">
            <div className="container">
                <div className="row">
                    <div className="col-sm-6 col-md-8 col-lg-8 hidden-xs">
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4 text-right">
                        <ul className="d-flex justify-content-between list-inline top-dark-right">
                            <li className="hidden-desktop">
                                <a href="/"><img className="img-fluid" alt="" src="/favicon.ico" /></a>
                            </li>
                            <li>
                                <i className="fa fa-envelope"></i> <a href="mailto:info@delta3a.com" target="_top">info@delta3a.com</a>
                            </li> &nbsp;
                            <li><i className="fa fa-phone"></i> +1 514 377 2441</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TopBar;