import React, { Component } from 'react';

import { ReCaptcha } from 'react-recaptcha-google'

import { sendMessage } from '../../api';

// eslint-disable-next-line
const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

class Contact extends Component  {

    state = {
        inputName: '',
        inputEmail: '',
        inputSubject: '',
        inputMessage: '',
        captchaToken: '',
        errorMessage: '',
        successMessage: '',
        submitting: false
    }
    
    constructor(props, context) {
        super(props, context);
        this.onLoadRecaptcha = this.onLoadRecaptcha.bind(this);
        this.verifyCallback = this.verifyCallback.bind(this);
    }

    componentDidMount() {
        if (this.captcha) {
            this.captcha.reset();
        }
    }

    canSubmit = () => {
        return !this.state.submitting && this.isFormValid();
    }

    isFormValid = () => {
        return this.state.inputEmail.length > 0 && this.state.inputName.length > 0 && 
            this.state.inputSubject.length > 0 && this.state.inputMessage.length > 0 && 
            emailRegex.test(String(this.state.inputEmail).toLowerCase()) && this.state.captchaToken.length > 0;
    }

    // Handle visitor's interaction with inputs
    handleInput = event => {
        this.setState({
            [event.target.name]: event.target.value
        })
    }
    
    handleCheckbox = event => {
        this.setState({
          [event.target.name]: event.target.checked
        })
    }
    
    onLoadRecaptcha() {
        if (this.captcha) {
            this.captcha.reset();
        }
    }

    verifyCallback(recaptchaToken) {
      this.setState({
        captchaToken: recaptchaToken
      })
    }

    handleFormSubmit = event => {

        event.preventDefault()
        
        if (this.isFormValid()) {
            
            this.setState({
                errorMessage: '',
                successMessage: '',
                submitting: true
            });
    
            const request = {
                name: this.state.inputName,
                email: this.state.inputEmail,
                subject: this.state.inputSubject,
                message: this.state.inputMessage,
                captchaToken: this.state.captchaToken
            };

            sendMessage(request, status => { 
                if (status) {
                    this.setState({
                        inputEmail: '',
                        inputSubject: '',
                        inputMessage: '',
                        inputName: '',
                        captchaToken: '',
                        errorMessage: '',
                        successMessage: 'Votre message a bien été envoyé.',
                        submitting: false
                    })
                }
                else {
                    this.setState({
                        submitting: false,
                        captchaToken: '',
                        errorMessage: 'Une erreur inattendue s\'est produite.'
                    })
                }

                if (this.captcha) {
                    this.captcha.reset();
                }
            });
        }
    }
      
    render() {
        return (
            <div id="contact">
                <div className="contact-me">
                    <div className="contact-me-grey">
                        <div className="divide40"></div>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="center-heading">
                                        <h2>NOUS <strong>CONTACTER</strong></h2>
                                        <span className="center-line"></span>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-2"></div>
                                <div className="col-md-8 m-30">
                                    {this.state.errorMessage.length > 0 && <div id="error">
                                        <div className="alert alert-danger">
                                            <button type="button" className="close" onClick={() => this.setState({ errorMessage: '' })}>×</button>
                                            <strong>{this.state.errorMessage}</strong>
                                        </div>
                                    </div>}
                                    {this.state.successMessage.length > 0 && <div id="error">
                                        <div className="alert alert-success">
                                            <button type="button" className="close" onClick={() => this.setState({ successMessage: '' })}>×</button>
                                            <strong>{this.state.successMessage}</strong>
                                        </div>
                                    </div>}
                                    <div className="form-contact">
                                        <form name="sentMessage" id="contactForm" method="post" noValidate>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="row control-group">
                                                        <div className="form-group col-12 controls">
                                                            <input type="text" name="inputName" value={this.state.inputName} onChange={this.handleInput} className="form-control" placeholder="NOM" id="name" required />
                                                            <p className="help-block">
                                                                {this.state.errorMessage.length > 0 && this.state.inputName.length === 0 && "SVP, entrer votre nom au complet."}
                                                            </p>
                                                        </div>
                                                    </div>

                                                </div>

                                                <div className="col-md-6">
                                                    <div className="row control-group">
                                                        <div className="form-group col-12 controls">
                                                            <input type="email" name="inputEmail" value={this.state.inputEmail} onChange={this.handleInput} className="form-control" placeholder="EMAIL" id="email" required />
                                                            <p className="help-block">
                                                                {this.state.errorMessage.length > 0 && this.state.inputEmail.length === 0 && "SVP, entrer votre adresse Email."}
                                                                {this.state.errorMessage.length > 0 && this.state.inputEmail.length > 0 && !emailRegex.test(String(this.state.inputEmail).toLowerCase()) && "SVP, entrer une adresse Email valide."}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row control-group">
                                                <div className="form-group col-12  controls">
                                                    <input type="text" name="inputSubject" value={this.state.inputSubject} onChange={this.handleInput} className="form-control" placeholder="OBJET" id="subject" required />
                                                    <p className="help-block">
                                                        {this.state.errorMessage.length > 0 && this.state.inputSubject.length === 0 && "SVP, entrer l'objet de votre message."}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="row control-group">
                                                <div className="form-group col-12 controls">
                                                    <textarea rows="5" name="inputMessage" value={this.state.inputMessage} onChange={this.handleInput} className="form-control" placeholder="TAPER VOTRE MESSAGE ICI..." id="message" required></textarea>
                                                    <p className="help-block">
                                                        {this.state.errorMessage.length > 0 && this.state.inputMessage.length === 0 && "SVP, entrer votre message."}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="row control-group">
                                                <div className="form-group col-12 controls">
                                                    <ReCaptcha
                                                        ref={(el) => {this.captcha = el;}}
                                                        size="normal"
                                                        render="explicit"
                                                        data-theme="dark" 
                                                        sitekey="6Lf9oSUTAAAAAJhmFfNif4mlV17OQ3EPsBcQVl-p"
                                                        onloadCallback={this.onLoadRecaptcha}
                                                        verifyCallback={this.verifyCallback}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="form-group col-12">
                                                    <button type="submit" 
                                                            onClick={this.handleFormSubmit} 
                                                            disabled={!this.canSubmit()} 
                                                            className={"btn btn-lg" + (this.canSubmit() ? " btn-theme-bg" : " btn-theme-bg-disabled")}>
                                                        ENVOYER
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Contact;