import firebase from 'firebase/app';

import 'firebase/functions';

firebase.initializeApp({
    apiKey: 'AIzaSyCNyV5xpgaSejpB8_y3X5SHmQ9fiiIXw5w',
    authDomain: 'delta3a-fbb3f.firebaseapp.com',
    projectId: 'delta3a-fbb3f'
  });
  
const addMessage = firebase.functions().httpsCallable('addMessage');

export const sendMessage = (request, callback) => {

    addMessage(request).then((response) => {
        
        callback(response.data.status);

    }).catch((error) => {
        callback(false);
    });

}