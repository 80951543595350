import React from 'react';

const Services = () => {
    return (
        <div id="services">
            <div className="divide70"></div>
            <div className="wide-img-showcase-row">
                <div className="row p-0 m-0">
                    <div className="col col-sm-6 hidden-xs img-analyse">
                    </div>
                    <div className="col col-xs-12 col-sm-6 gray">
                        <div className="no-padding-inner gray">
                            <h3 className="wow animated fadeInDownfadeInRight">NOS <span className="colored-text">SERVICES</span></h3>
                            <div className="services-box margin30 wow animated fadeInRight">
                                <div className="services-box-icon">
                                    <i className="fa fa-line-chart"></i>
                                </div>
                                <div className="services-box-info">
                                    <h4>ÉTUDES, ANALYSES, STRATÉGIES</h4>
                                    <p className="text-justify mr-20">
                                        L’équipe de Delta3A vous donnera l’appui nécessaire pour mener à bien votre étude de marché sur les secteurs présentant de réelles opportunités d’affaire et vous apportera les conseils nécessaires pour bien déployer vos stratégies de développement en Afrique.
                                    </p>
                                </div>
                            </div>
                            <div className="services-box margin30 wow animated fadeInRight">
                                <div className="services-box-icon">
                                    <i className="fa fa-globe"></i>
                                </div>
                                <div className="services-box-info">
                                    <h4>IMPORT – EXPORT</h4>
                                    <p className="text-justify mr-20">
                                        Delta3A de par sa vision veut jouer un rôle majeur dans le développement du commerce international.
                                    </p>
                                    <p className="text-justify mr-20">
                                        Delta3A offre ses services d’évaluation et de sélection de fournisseurs à travers l’Amérique du Nord et l’Asie afin de permettre aux entrepreneurs africains d’avoir des équipements et produits de qualités utilisés dans certains secteurs tels que l’agriculture, la construction et les énergies renouvelables.                                    </p>
                                </div>
                            </div>
                        </div>
                    </div> 
                </div>
            </div>
        </div>
    );
}

export default Services;