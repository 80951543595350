import React from 'react';

const About = () => {
    return (
        <div id="about">
            <div className="divide80"></div>
            <div className="container">
                <div className="row">
                    <div className="col-sm-8 col-sm-offset-2 mx-auto">
                        <div className="center-heading">
                            <h2>À PROPOS DE <strong>NOUS</strong></h2>
                            <span className="center-line"></span>
                            <p className="sub-text margin20 text-justify ml-20 mr-20">
                                Delta3A regroupe divers talents originaires de l’Afrique qui, riches de leurs formations et expériences professionnelles acquises dans les quatre coins du monde, ambitionnent de contribuer à la croissance et le rayonnement au niveau international de leur pays d’origine. Depuis leur siège social établi à Montréal au Canada et de sa succursale à Dakar au Sénégal, l’équipe de Delta3A se positionne comme le véritable catalyseur des échanges commerciaux de biens et de services entre l’Afrique – l’Amérique du Nord et l’Asie.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6 col-sm-6 margin30">
                        <div className="colored-boxed red">
                            <i className="pe-7s-folder"></i>
                            <h3>MISSION</h3>
                            <span className="center-line"></span>
                            <p className="sub-text text-justify ml-20 mr-20">
                                Jouer un rôle de facilitateur dans le développement d’affaire entre les 3A (Afrique – Amérique du Nord – Asie) et établir de nouveaux partenariats commerciaux, de nouvelles alliances stratégiques. L’équipe de chez Delta3A accompagne les porteurs de projet et les investisseurs à mieux saisir les opportunités d’affaire.
                            </p>
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-6 margin30">
                        <div className="colored-boxed blue">
                            <i className="pe-7s-glasses"></i>
                            <h3>VISION</h3>
                            <span className="center-line"></span>
                            <p className="sub-text text-justify ml-20 mr-20">
                                A travers ses partenaires établis dans les 3A et évoluant dans différents secteurs d’activité comme les nouvelles technologies, les énergies renouvelables, l’agrobusiness, Delta3A sera le passage menant vers le succès d’un commerce international plus équitable.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default About;