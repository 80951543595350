import React, { Component } from 'react';

import { StickyContainer, Sticky } from 'react-sticky';

import TopBar from './_topbar';
import Header from './_header';
import Content from './_content';
import Footer from './_footer';

class Home extends Component {

    render() {
        return (
            <div>
                <TopBar />
                <StickyContainer>
                    <Sticky>
                    {({
                        style,
            
                        // the following are also available but unused in this example
                        isSticky,
                        wasSticky,
                        distanceFromTop,
                        distanceFromBottom,
                        calculatedHeight
                    }) => (
                        <header style={style}>
                            <Header />
                        </header>
                    )}
                    </Sticky>
                    <Content />
                    <Footer />
                </StickyContainer>              
            </div>
        );
    }
}

export default Home;