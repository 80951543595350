import React from 'react';
import { fadeInDown } from 'react-animations';
import Radium, {StyleRoot} from 'radium';
 
const styles = {
  fadeInDown: {
    animation: 'x 1s',
    animationName: Radium.keyframes(fadeInDown, 'fadeInDown')
  }
}
 
class FadeInDown extends React.Component {
  render() {
    const { children } = this.props;

    return (<StyleRoot>
                <div style={styles.fadeInDown}>
                    {children}
                </div>
            </StyleRoot>);
  }
}

export default FadeInDown