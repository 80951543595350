import React, { Component } from 'react';

import { loadReCaptcha } from 'react-recaptcha-google'

import './style';

import Home from './routes/home';

class App extends Component {

  componentDidMount() {
    loadReCaptcha();
  }

  render() {
    return (
      <div className="App">
        <Home />
      </div>
    );
  }
}

export default App;
