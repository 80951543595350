import React from 'react';

const Header = () => {
    return (
        <div className="navbar navbar-default navbar-static-top yamm sticky hidden-mobile" role="navigation">
            <div className="container">
                <div className="navbar-header">
                    <a className="navbar-brand" href="/">&nbsp;</a>
                </div>
                <ul className="nav navbar-nav navbar-right">
                    <li><a href="/"><span>ACCUEIL</span></a></li>
                    <li><a href="/#about"><span>A PROPOS</span></a></li>
                    <li><a href="/#services"><span>SERVICES</span></a></li>
                    <li className="last"><a href="/#contact"><span>CONTACT</span></a></li>
                </ul>
            </div>
        </div>
    );
}

export default Header;