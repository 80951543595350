import React, { useState } from 'react';

import FadeInDown from '../../animations/fadeInDown';
import Carousel from 'react-bootstrap/Carousel'

const items = [
  {
    key: 'slide1',
    src: '/images/slide-1.jpg',
    altText: 'Catalyseur des échanges commerciaux',
    content: (<div className="carousel-content-1">
                {"Catalyseur des échanges commerciaux".toUpperCase()} <br />
                {"entre l’Afrique – l’Amérique du Nord et l’Asie".toUpperCase()}
              </div>)
  },
  {
    key: 'slide2',
    src: '/images/slide-2.jpg',
    altText: 'Vision du conseil',
    content: (<div className="carousel-content-2">
                <div className="title">
                  <p>{"Vision du conseil basée sur : ".toUpperCase()}</p>
                </div>
                <div className="checkpoint">
                  <div className="check">
                    <i className="fa fa-check"></i>
                  </div>
                  <div className="point">l'innovation</div>
                </div>
                <div className="checkpoint">
                  <div className="check">
                    <i className="fa fa-check"></i>
                  </div>
                  <div className="point">la confiance</div>
                </div>
                <div className="checkpoint">
                  <div className="check">
                    <i className="fa fa-check"></i>
                  </div>
                  <div className="point">la qualité</div>
                </div>
                <div className="more">
                  <p><a href="/#services">{"En apprendre davantage".toUpperCase()}</a></p>
                </div>
              </div>)
  },
  {
    key: 'slide3',
    src: '/images/slide-3.jpg',
    altText: 'Clés du succès',
    content: (<div className="carousel-content-3">
                {"Delta3A détient les clés du succès".toUpperCase()} <br />
                {"de vos projets d’affaire".toUpperCase()}
              </div>)
  }
];

const Slides = () => {
    
    const [index, setIndex] = useState(0);
    const [direction, setDirection] = useState(null);
  
    const handleSelect = (selectedIndex, e) => {
      setIndex(selectedIndex);
      setDirection(e.direction);
    };
  
    const slides = items.map((item) => {
        return (
          <Carousel.Item key={item.key}>
            <img src={item.src} className="img-fluid" alt={item.altText} />
            <Carousel.Caption>
              <FadeInDown>{item.content}</FadeInDown>
            </Carousel.Caption>
          </Carousel.Item>
        );
    });

    return (
      <Carousel activeIndex={index} direction={direction} onSelect={handleSelect}>
        {slides}
      </Carousel>
    );
  }

export default Slides;
