import React from 'react';

const Footer = () => {
    return (
        <footer id="footer">
            <div className="container">
                <div className="row">
                    <div className="col-md-5 col-sm-6">
                        <div className="footer-col">
                            <h3>ABOUT US</h3>
                            <p className="text-justify">
                                Delta3a est une entreprise canadienne créée en 2014 par de jeunes talents originaires de l'Afrique. 
                                Notre but est d'offrir des services de qualité aux entrepreneurs afin de les aider à s'implanter en Afrique.
                            </p>          
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-6">
                        &nbsp;
                    </div>
                    <div className="col-md-3 col-sm-6">
                        <div className="footer-col">
                        <h3>CONTACT</h3>
                        <ul className="list-unstyled contact">
                            <li><p><strong><i className="fa fa-envelope"></i></strong>
                            <a href="mailto:info@delta3a.com" target="_top">info@delta3a.com</a>
                            </p></li>
                            <li> <p><strong><i className="fa fa-phone"></i></strong> +1 514 377 2441</p></li>
                            <li> <p><strong><i className="fa fa-skype"></i></strong> delta3a</p></li>
                        </ul>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 text-center">
                        <div className="footer-btm">
                            <span>&copy; {new Date().getFullYear()} - Delta3a Canada</span><br />
                            <a href="/#" className="totop"><i className="fa fa-angle-double-up"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;